import { isValidElement, ReactNode, type FunctionComponent } from 'react';
import ScreenStateInfo from '@/components/ScreenStateInfo';
import InfoBanner from '@/components/InfoBanner';
import Icon from '@/components/Icon';
import classes from './ErrorInterstitial.module.css';
import useEnvironment from '@/hooks/useEnvironment';

export interface ErrorInterstitialProps {
  title?: string;
  description?: string;
  devInfo?: string | ReactNode;
}

const ErrorInterstitial: FunctionComponent<ErrorInterstitialProps> = ({
  title = 'Error',
  description,
  devInfo,
}) => {
  const { isProduction } = useEnvironment();
  const detail =
    devInfo &&
    (isProduction ? null : isValidElement(devInfo) ? (
      devInfo
    ) : (
      <p className={classes.message}>{devInfo}</p>
    ));

  return (
    <div className={classes.root}>
      <ScreenStateInfo
        title={title}
        description={description}
        icon={
          <Icon
            meaning="negative"
            variant="two-tone"
            name="feather/x"
            size="xxl"
          />
        }
        footerSection={
          detail && (
            <InfoBanner meaning="negative" title="Error Detail">
              {detail}
            </InfoBanner>
          )
        }
      />
    </div>
  );
};

export default ErrorInterstitial;
