import {
  createContext,
  type FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import decode from 'jwt-claims';

export type CognitoUser = {
  email: string;
  emailVerified: boolean;
  // TODO: SSO is to be implemented. For now, assume that all users are not SSO users. [CRE-2563]
  isSSOAuthUser?: boolean;
  staffId?: string | null;
};

interface CognitoAuthProps extends PropsWithChildren {
  token: string | null;
  authUser: CognitoUser | null;
  loading: boolean;
  refreshing: boolean;
  error: Error | null;
  setToken: (token: string) => void;
  clearAuthState: () => void;
  setRefreshing: (refreshing: boolean) => void;
  setError: (error: Error | null) => void;
}

const stub = (): never => {
  throw new Error('You must wrap your component in <CognitoAuthProvider>.');
};

export const CognitoAuthContext = createContext<CognitoAuthProps>({
  token: null,
  authUser: null,
  loading: false,
  refreshing: false,
  error: null,
  setToken: stub,
  clearAuthState: stub,
  setRefreshing: stub,
  setError: stub,
});

export interface CognitoAuthProviderProps extends PropsWithChildren {}

/**
 * This provider manages parsing and storage of the current user's session information. It allows
 * for user sessions to be stored independently from the authentication functionality itself, avoiding
 * confusing circular dependencies where auth itself depends on API accesses that may refer to
 * the auth context.
 * @param param0
 * @returns
 */
export const CognitoAuthProvider: FunctionComponent<
  CognitoAuthProviderProps
> = ({ children }) => {
  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const authUser = useMemo<CognitoUser | null>(() => {
    if (!token) {
      return null;
    }
    const decodedToken = decode(token);
    return {
      email: decodedToken.email,
      emailVerified: decodedToken.email_verified,
      staffId: decodedToken['custom:staff_id'] ?? null,
    };
  }, [token]);

  const clearAuthState = () => {
    setToken(null);
  };

  // Setting the token with identical token data caused a cascade of changes, so
  // verify it has updated before setting.
  const setTokenIfChanged = useCallback(
    (newToken: string) => {
      if (token === newToken) {
        return;
      }
      setToken(newToken);
    },
    [token]
  );

  return (
    <CognitoAuthContext.Provider
      value={{
        // initial loading state is true if refreshing when no token is present.
        // Refreshing a token after initial auth will not set `loading` to true.
        loading: refreshing && !token,
        refreshing,
        error,
        token,
        authUser,
        setToken: setTokenIfChanged,
        clearAuthState,
        setRefreshing,
        setError,
      }}>
      {children}
    </CognitoAuthContext.Provider>
  );
};

/**
 * This hook provides the current user's session information.
 */
export const useCognitoSession = () => {
  const { loading, refreshing, authUser, token, error } =
    useContext(CognitoAuthContext);
  return {
    loading,
    refreshing,
    authenticated: !!token,
    token,
    authUser,
    error,
  };
};

/**
 * This hook enables interacting with the CognitoAuthContext to update the current user's session.
 */
export const useCognitoAuth = () => {
  const { setToken, clearAuthState, setRefreshing, setError } =
    useContext(CognitoAuthContext);
  return { setToken, clearAuthState, setRefreshing, setError };
};
