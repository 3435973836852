import React, {
  isValidElement,
  PropsWithChildren,
  ReactNode,
  type FunctionComponent,
} from 'react';
import classes from './InfoBanner.system.module.css';
import cx from 'clsx';
import { RegisteredIcon } from '@/lib/icons';
import {
  ActivityDesignMeaning,
  SentimentDesignMeaning,
} from '@/types/design-semantics';
import Icon from '@/components/Icon';
import ActionIcon from '@/components/ActionIcon';
import { useId } from '@mantine/hooks';

export interface InfoBannerProps extends PropsWithChildren {
  icon?: RegisteredIcon;
  className?: string;
  meaning?: SentimentDesignMeaning | ActivityDesignMeaning;
  title: string;
  details?: ReactNode;
  onDismiss?: () => void;
}

const MeaningIcons: Record<
  Required<InfoBannerProps>['meaning'],
  RegisteredIcon
> = {
  neutral: 'custom/i',
  positive: 'feather/check',
  negative: 'feather/x',
  warning: 'custom/exclamation',
  info: 'custom/i',
  active: 'custom/i',
  inactive: 'custom/i',
};

const InfoBanner: FunctionComponent<InfoBannerProps> = ({
  icon,
  className,
  meaning = 'neutral',
  title,
  children,
  onDismiss,
}) => {
  const domId = useId();
  const iconName: RegisteredIcon = icon ?? MeaningIcons[meaning];

  const message = isValidElement(children) ? (
    <div className={classes.message}>{children}</div>
  ) : (
    <p className={classes.message}>{children}</p>
  );

  const description =
    meaning === 'positive'
      ? 'Positive info message'
      : meaning === 'negative'
      ? 'Negative info message'
      : meaning === 'warning'
      ? 'Warning info message'
      : 'Info message';

  const dataAttrs = {
    'data-meaning': meaning,
  };

  return (
    <aside
      className={cx(classes.root, className)}
      {...dataAttrs}
      aria-describedby={domId}>
      <div id={domId}>
        <Icon
          name={iconName}
          variant="filled"
          meaning={meaning}
          size="md"
          alt={description}
        />
      </div>
      <div>
        <header>
          <h2 className={classes.title}>{title}</h2>
        </header>
        {message}
      </div>
      {onDismiss && (
        <footer className={classes.dismiss}>
          <ActionIcon
            size="md"
            name="feather/x"
            onClick={onDismiss}
            aria-label="Dismiss"
          />
        </footer>
      )}
    </aside>
  );
};

export default InfoBanner;
