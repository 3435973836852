import { forwardRef, type FunctionComponent } from 'react';
import classes from './Icon.system.module.css';
import cx from 'clsx';
import SvgIcon from '@/components/SvgIcon';
import {
  SentimentDesignMeaning,
  ImportanceDesignMeaning,
  ActivityDesignMeaning,
  DefaultDesignMeaning,
  BrandDesignMeaning,
} from '@/types/design-semantics';
import type { RegisteredIcon } from '@/lib/icons';

export interface IconProps {
  name: RegisteredIcon;
  alt?: string;
  variant?: 'filled' | 'enclosed' | 'two-tone' | 'default';

  /** The semantic coloring of the icon. */
  meaning?:
    | SentimentDesignMeaning
    | ImportanceDesignMeaning
    | ActivityDesignMeaning
    | BrandDesignMeaning
    | DefaultDesignMeaning;

  /** If marked as interactive the icon will `inherit` color on hover/focus. */
  interactive?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  className?: string;
  'aria-hidden'?: boolean;
}

/**
 * Renders the named icon from the icon sprite.
 *
 * You must add new icons to the `icons.mjs` definition file in `src/lib` to use them here.
 *
 * @param name - The name of the icon to render.
 * @param variant - Filled is encircled with a solid background, enclosed is encircled with a border, default is plain.
 * @param meaning - The semantic coloring of the icon.
 * @param size - The size of the icon. Per the Caire Design System, we generally use `md` (default) or `sm`.
 */
const Icon: FunctionComponent<IconProps> = forwardRef<
  HTMLSpanElement,
  IconProps
>(
  (
    { name, alt, variant, meaning, interactive, size, className, ...rest },
    ref
  ) => {
    const dataAttrs = {
      'data-variant': variant,
      'data-meaning': meaning,
      'data-size': size,
      'data-interactive': interactive ? 'true' : undefined,
    };

    return (
      <span
        ref={ref}
        className={cx(classes.root, className)}
        {...dataAttrs}
        {...rest}>
        <SvgIcon name={name} alt={alt} />
      </span>
    );
  }
);

// Eslint will complaint about missing displayName by using
// fowrdard ref unless we spcify it.
Icon.displayName = 'Icon';

export default Icon;
