import { ReactNode, type FunctionComponent } from 'react';
import classes from './ScreenStateInfo.module.css';
import cx from 'clsx';

export interface ScreenStateInfoProps {
  title: string;
  description?: string;
  icon?: ReactNode;
  footerSection?: ReactNode;
}

const ScreenStateInfo: FunctionComponent<ScreenStateInfoProps> = ({
  title,
  description,
  icon,
  footerSection,
}) => {
  return (
    <div className={cx(classes.root)}>
      {icon && <header className={cx(classes.header)}>{icon}</header>}

      <div className={cx(classes.content)}>
        <h1 className={cx(classes.title)}>{title}</h1>
        {description && (
          <p className={cx(classes.description)}>{description}</p>
        )}
      </div>

      {footerSection && <footer>{footerSection}</footer>}
    </div>
  );
};

export default ScreenStateInfo;
