import {
  createElement,
  useEffect,
  useState,
  type FunctionComponent,
} from 'react';
import classes from './SvgIcon.module.css';
import { useId } from '@mantine/hooks';
import type { RegisteredIcon } from '@/lib/icons';

export interface SvgIconProps {
  name: RegisteredIcon;
  alt?: string;
}

// In production builds, icons are built into a sprite sheet. This path is
// well known as `/icons/sprite.svg`, but the build process may include a unique
// hash in the filename for caching. This environment variable is configured in the
// next config and applied here when set.
const spriteHash = process.env.SVG_SPRITE_HASH;
const spritePath = spriteHash
  ? `/icons/sprite.${spriteHash}.svg`
  : '/icons/sprite.svg';

/**
 * Returns an inline SVG asset for the requested icon name. In production, it references
 * the icon sprite, in development it returns the full inline SVG. In test environment,
 * it returns a stub custom element.
 *
 * If the icon name is not registered in the IconProvider, it returns null.
 *
 * It's expected that this component be used internally to render the graphic within a higher-
 * level Icon component that exposes styling and accessibility props.
 */
const SvgIcon: FunctionComponent<SvgIconProps> = ({ name, alt }) => {
  const altElementId = `alt-${useId()}`;

  // In test environment, return a stub element representing the SVG without inlining it.
  if (process.env.NODE_ENV === 'test') {
    return <TestSvgIconPlaceholder name={name} alt={alt} />;
  }

  // In development, we dynamically import SVGs, so return inline SVG component
  if (process.env.NODE_ENV === 'development') {
    return <InlineSvgIcon name={name} />;
  }

  // In production, we reference icons built in the SVG sprite, so return
  // a static SVG reference.
  return (
    <svg
      className={classes.root}
      role={alt ? 'img' : undefined}
      aria-labelledby={alt ? altElementId : undefined}>
      {alt && <title id={altElementId}>{alt}</title>}
      <use href={`${spritePath}#${name}`} />
    </svg>
  );
};

/**
 * In Test mode, we render a placeholder element to represent the SVG icon, Which helps keep
 * snapshots tidy.
 *
 * Importing the SVG using nextjs's dynamic import ensures that the icon exists in the library,
 * while TypeScript type checking ensures that the icon name will be present in the built sprite.
 *
 */
const TestSvgIconPlaceholder: FunctionComponent<SvgIconProps> = ({
  name,
  alt,
}) => {
  // Although we don't render the SVG in tests, we call the dynamic import to ensure the icon
  // exists in the library. This will throw an error if the asset is missing.
  import(`@/icons/${name}.svg`);

  return createElement('svg-icon', {
    name,
    alt,
    role: 'graphics-document',
    // `graphics-document` is the default internal aria role for SVG documents. Included as a
    // guide for accessibility validation of this custom element.
  });
};

/**
 * This component dynamically imports an SVG icon as a module using svgr/webpack,
 * and renders it as inline SVG. It is for internal use by SvgIcon.
 *
 * Note that this development-only rendering also does not render alt text.
 */
const InlineSvgIcon: FunctionComponent<SvgIconProps> = ({ name }) => {
  const [Icon, setIcon] = useState<FunctionComponent<
    Partial<HTMLElement>
  > | null>(null);

  useEffect(() => {
    import(`@/icons/${name}.svg`).then(({ default: ReactComponent }) => {
      // Note! `setState` must be set with a function to return the imported component,
      // else the component will be executed by setState, resulting in storing a serialized
      // JSX Element instead.
      setIcon(() => ReactComponent);
    });
  }, [name]);

  if (!Icon) {
    return <span className={classes.root} />;
  }

  return <Icon className={classes.root} />;
};

export default SvgIcon;
