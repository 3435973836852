import {
  useContext,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import decode from 'jwt-claims';
import { useAuthentication } from '@/contexts/Authentication';
import useQueryString from '@/hooks/useQueryString';
import { useListProgramsQuery, ListProgramsQuery } from '@/lib/graphql/shared';
import { programIdForSlug } from '@/lib/config';
import ProgramSwitchContext, {
  ProgramSwitchData,
} from './ProgramSwitchContext';

export interface UseCurrentProgramHook extends ProgramSwitchData {
  currentProgram: ListProgramsQuery['programs'][number] | undefined;
  programs: ListProgramsQuery['programs'];
}

export interface ProgramSwitchProps extends PropsWithChildren {}

/**
 * Determines the current active program context from a hierarchy of sources:
 *
 * 1. The program associated with the current user, if authenticated.
 * 2. The program specified for the current page, by the `program=slug` query parameter.
 * 3. The program specified by the `NEXT_PUBLIC_PROGRAM` environment variable.
 *
 * The environment variable is maintained for backward compatibility, but should be
 * deprecated in future in favour of a different method of specifying the 'logged out'
 * program context where needed. (Or, not requiring it at all.)
 */
export const ProgramSwitchProvider: FunctionComponent<ProgramSwitchProps> = ({
  children,
}) => {
  // The user's list of associated programs comes from the authToken JWT, which we
  // manually decode here because auth0 does not expose the custom scope data.
  const { authToken, isLoading } = useAuthentication();
  const decodedToken = useMemo<Record<string, any> | undefined>(() => {
    if (authToken) {
      try {
        return decode(authToken);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [authToken]);

  // Programs are marked as active, so filter for active ones only.
  const activeProgramIds = useMemo(() => {
    // The user's programs are stored in the `app_metadata` field of the JWT, and
    // differs whether they are staff or patient. However, that is not relevant to
    // this context, so take whichever is present.
    const userPrograms =
      decodedToken?.app_metadata?.staff_programs ||
      decodedToken?.app_metadata?.patient_programs ||
      {};

    return Object.entries(userPrograms).flatMap(([program, status]) =>
      status === 'active' ? program : []
    );
  }, [decodedToken]);

  // Right now, we assume accounts will only have a single active program and we take
  // the first one. In future, we may make this switchable and store the active program
  // in a cookie.
  const selectedUserProgram = activeProgramIds[0];

  // Logged out fallback 1: A page may specifying `program=upliv` slug in the query string.
  const [pageProgram, setPageProgram] = useState<string | undefined>();
  const { program: queryStringProgramId } = useQueryString('program');

  // Persist the page program slug from the query string for re-use on future pages.
  useEffect(() => {
    if (queryStringProgramId) {
      setPageProgram(queryStringProgramId);
    }
  }, [queryStringProgramId]);

  // Logged out fallback 2: The environment variable `NEXT_PUBLIC_PROGRAM` specifies
  // a single program for this build.
  const buildProgramSlug = process.env.NEXT_PUBLIC_PROGRAM || 'upliv';
  const buildProgram = buildProgramSlug
    ? programIdForSlug(buildProgramSlug)
    : undefined;

  // If authentication is resolved, we can specify a current program.
  //
  // We fall back to the page or build program while this is loading, which _could_
  // cause a flash-of-wrong-config, but the alternative is having to block the entire
  // application until the user's session is resolved. This is a trade-off and we should
  // revisit it in future after we have settled on what our multi-program deployments
  // will really look like.
  const currentProgramId = selectedUserProgram || pageProgram || buildProgram;

  return (
    <ProgramSwitchContext.Provider
      value={{
        loading: isLoading,
        currentProgramId,
        activeProgramIds,
      }}>
      {children}
    </ProgramSwitchContext.Provider>
  );
};

/**
 * This hook exposes the low-level context data for the current program — effectively
 * just the UUIDs. It should be used only in contexts where the Apollo GraphQL context
 * has not yet be initialized. Otherwise, prefer `useCurrentProgram` which provides the
 * full program object.
 */
export const useCurrentProgramContext = (): ProgramSwitchData => {
  return useContext(ProgramSwitchContext);
};

/**
 * Access the user's hydrated current program and other active programs. This hook requires
 * it be used within an Apollo GraphQL context.
 */
export const useCurrentProgram = (): UseCurrentProgramHook => {
  const { loading, currentProgramId, activeProgramIds } =
    useCurrentProgramContext();

  // We fetch all programs and filter on the client side to the user's active
  // subset. This is not ideal, but the current API does not support filtering.
  const { data: allPrograms, loading: programsQueryLoading } =
    useListProgramsQuery({
      fetchPolicy: 'cache-first',
    });

  // When authenticated, filter the hydrated version of the programs objects by the
  // user-active IDs. Otherwise, return all programs as 'available'.
  const programs = useMemo(() => {
    return activeProgramIds.length > 0
      ? allPrograms?.programs.filter((_) => activeProgramIds.includes(_.id)) ??
          []
      : allPrograms?.programs ?? [];
  }, [activeProgramIds, allPrograms]);

  const currentProgram = useMemo(
    () => allPrograms?.programs.find((_) => _.id === currentProgramId),
    [allPrograms, currentProgramId]
  );

  return {
    loading: loading || programsQueryLoading,
    currentProgramId,
    activeProgramIds,
    currentProgram,
    programs,
  };
};
