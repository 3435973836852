/**
 * Wrapper around ENVIRONMENT envvar to gate functionality based on the
 * deploy environment the app is running in.
 * @returns
 */
const useEnvironment = () => {
  return {
    isProduction: process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod',
    isLocal: process.env.NEXT_PUBLIC_ENVIRONMENT === 'local',
  };
};

export default useEnvironment;
