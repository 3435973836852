import {
  useEffect,
  useRef,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import { ApolloProvider } from '@apollo/client';
import { useCurrentProgramContext } from '@/contexts/ProgramSwitch';
import { useAuthentication } from '@/contexts/Authentication';
import { useTenantId } from '@/contexts/TenantConfig';
import createApolloClient from '@/lib/graphql/apollo-client';

// TODO: CRE-3189: Refactor this to not keep regenerating the client when
// request metadata changes. We want the Apollo itself to be stable.
// Store the client in a ref, provide a function to update the internal fields.
const AuthenticatedApolloProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { authToken } = useAuthentication();
  const { currentProgramId } = useCurrentProgramContext();
  const tenantId = useTenantId();

  const clientRef = useRef<ReturnType<typeof createApolloClient> | never>(
    null as never
  );

  if (clientRef.current === null) {
    clientRef.current = createApolloClient({
      token: authToken,
      programId: currentProgramId,
      tenantId,
    });
  }

  useEffect(() => {
    clientRef.current.update({
      token: authToken ?? undefined,
      tenantId,
      programId: currentProgramId,
    });
  }, [authToken, currentProgramId, tenantId]);

  return (
    <ApolloProvider client={clientRef.current.client}>
      {children}
    </ApolloProvider>
  );
};

export default AuthenticatedApolloProvider;
