import type { ConversationMessageInterface } from '@/components/Messages/ConversationMessage/ConversationMessage';
import { ConversationPreviewInterface } from '@/components/Messages/ConversationPreview/ConversationPreview';
import { UserPerspective } from '@/types/userPerspective';
import { Conversation, Message } from '@twilio/conversations';

export function buildLatestMessagePreview(
  message: Message
): ConversationMessageInterface {
  // It is safe to cast the message attributes as any then
  // to author's object with first, last names and role because
  // we explicitly set it in useTwilioCreateConversation hook. However,
  // we should refactor the create hook to use a well-defined AuthorAttribute
  // so that we don't have to manually cast it like this.
  // TODO: https://cairehealth.atlassian.net/browse/CRE-1317
  const author = (message.attributes as any).author as {
    firstName: string;
    lastName: string;
    role: string;
  };

  return {
    sid: message.sid,
    body: message?.body ?? '',
    dateCreated: message.dateCreated!,
    dateUpdated: message.dateUpdated!,
    author: author,
  };
}

export async function buildConversationPreview(
  conversation: Conversation,
  unreadMessagePerspective: UserPerspective
): Promise<ConversationPreviewInterface | null> {
  const participantCount = await conversation.getParticipantsCount();

  // If there are no participants in the conversation, we should not attempt to render
  // it as we do not have permission to access the messages.
  if (participantCount === 0) {
    return null;
  }

  // Param pageSize set to 1 to fetch only the latest message
  const messages = await conversation
    .getMessages(1)
    .then(({ items }) => items)
    .catch(() => {
      return null;
    });
  const unreadMessagesCount = await conversation.getUnreadMessagesCount();

  // Handle edge case where a conversation was created successfully but the first message
  // failed to send. In which case, we don't want to render a conversation preview and simply
  // return null
  if (messages === null || !messages[0]) {
    return null;
  }

  const latestMessagePreview = buildLatestMessagePreview(messages[0]);

  let showUnreadIndicator =
    (unreadMessagePerspective === UserPerspective.Patient &&
      latestMessagePreview.author.role !== UserPerspective.Patient) ||
    (unreadMessagePerspective === UserPerspective.Staff &&
      latestMessagePreview.author.role === UserPerspective.Patient);

  if (unreadMessagesCount === 0) {
    showUnreadIndicator = false;
  }

  const attributes = conversation.attributes as {
    programId: string;
    patient: {
      firstName: string;
      lastName: string;
      role: string;
    };
    staff: {
      firstName: string;
      lastName: string;
      role: string;
    };
  };
  const parseError =
    'Missing attribute name - a name attribute is required but not provided';

  return {
    sid: conversation.sid,
    accountSid: 'undefined',
    // Note: dateCreated and dateUpdated are not optional properties
    // so they must be available if a conversation is valid. No need
    // for checking null or undefined.
    // friendlyName is also not listed as optional, so most likely
    // we will display an empty string if there is no friendlyName set
    // https://www.twilio.com/docs/conversations/api/conversation-resource#conversation-properties
    friendlyName: conversation.friendlyName!,
    dateCreated: conversation.dateCreated!,
    dateUpdated: conversation.dateUpdated!,
    showUnreadIndicator: showUnreadIndicator,
    unreadMessagesCount:
      // istanbul ignore next
      typeof unreadMessagesCount === 'number' && unreadMessagesCount >= 0
        ? unreadMessagesCount
        : 0,
    latestMessage: latestMessagePreview,
    // Note: Participation attribute will be implemented in CRE-1030
    participants: {
      patient: {
        firstName: attributes.patient?.firstName || parseError,
        lastName: attributes.patient?.lastName || parseError,
      },
      staff: {
        firstName: attributes.staff?.firstName || parseError,
        lastName: attributes.staff?.lastName || parseError,
        role: UserPerspective.Staff,
        educationSuffix: 'Unknown',
      },
    },
  };
}

export interface StringJSONObject {
  [k: string]: string;
}

function getAuthorAttributes(message: Message) {
  const attributes = message.attributes as unknown as StringJSONObject;
  if (attributes && 'author' in attributes) {
    // istanbul ignore next
    const {
      firstName = 'Unknown',
      lastName = 'Unknown',
      role = 'Unknown',
      educationalSuffix = 'Unknown',
    } = attributes.author as unknown as StringJSONObject;

    return {
      firstName,
      lastName,
      role,
      educationSuffix: educationalSuffix,
    };
  }
  return {
    firstName: 'Unknown',
    lastName: 'Unknown',
    role: 'Unknown',
    educationSuffix: 'Unknown',
  };
}

export async function buildConversationMessageInterface(
  messages: Message[]
): Promise<ConversationMessageInterface[]> {
  let conversationMessageInterfaces: ConversationMessageInterface[] = [];
  messages.map((message) => {
    conversationMessageInterfaces.push({
      sid: message.sid,
      author: getAuthorAttributes(message),
      body: message.body ? message.body : '',
      dateCreated: message.dateCreated!,
      dateUpdated: message.dateUpdated!,
    });
  });

  // Sort the messages in ascending order
  return conversationMessageInterfaces.sort(
    (a, b) =>
      new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()
  );
}
