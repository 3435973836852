import { HomepageWidget, type ProgramConfig } from './types';

/**
 * NOTE: This Upliv config has been modified with generic Caire branding
 * configuration, so that we're able to display the Patient Portal in a
 * generic fashion, while not yet updating the user fixtures to allow creating
 * user accounts for non-Upliv programs.
 */
const upliv: ProgramConfig = {
  programName: 'Huntington Hospital',
  programId: '6f3fd233-53f4-3e1d-b38e-3083917b67d8',
  programSlug: 'upliv',
  programTheme: 'demo',
  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.uplivhealth.com/privacy-notice',
  termsOfServiceUrl: 'https://www.uplivhealth.com/terms-and-conditions',
  alphaTermsUrl:
    'https://www.caire.health/terms-and-conditions-for-upliv-alpha-test',

  welkinCloudLink: 'https://care.live.welkincloud.io/signin',

  noticeOfPrivacyPracticesUrl:
    'https://www.uplivhealth.com/notice-of-privacy-practices',
  telehealthConsentUrl: 'https://www.uplivhealth.com/telehealth-consent',
  supportPhoneNumber: '6193771244',
  supportEmail: 'upliv-support@caire.health',
  supportFaqUrl: 'https://www.uplivhealth.com/faq',

  programLogo: {
    url: '/images/demo/caire-icon-blue.svg',
    height: 32,
    width: 30,
    alt: 'Caire Demo',
  },

  lightProgramLogo: {
    url: '/images/demo/caire-icon-blue.svg',
    height: 32,
    width: 30,
    alt: 'Caire Demo',
  },

  homepage: [
    {
      component: HomepageWidget.WelcomeWidget,
    },
    {
      component: HomepageWidget.FindCareCard,
    },
    {
      component: HomepageWidget.PreventativeToDoList,
    },
    {
      component: HomepageWidget.OtherWorkBenefits,
    },
  ],

  enrollment: {
    steps: ['eligibility-check'],
  },
};

export default upliv;
