import { useAuthentication } from '@/contexts/Authentication';
import { useCognitoAction } from '@/contexts/Cognito';
import { useIdle } from '@mantine/hooks';
import decode from 'jwt-claims';
import { ReactNode, useCallback, useEffect, useState } from 'react';

// TODO CRE-3190: Revisit IdleSessionTimeout for use with Cognito. We've
// had to patch some off/noisy behaviour and the effectiveness of this component
// is in doubt. Revisit when prioritized.
export function IdleSessionTimeout({ children }: { children: ReactNode }) {
  const [idleTimeout, setIdleTimeout] = useState<number | null>(null);
  const { authToken, authenticated } = useAuthentication();
  const { refetchUserMetadata } = useCognitoAction();

  const isIdle = useIdle((idleTimeout ?? 0) / 3);

  const keepAliveFunction = useCallback(() => {
    refetchUserMetadata();
  }, [refetchUserMetadata]);

  useEffect(() => {
    if (authenticated && !idleTimeout && authToken) {
      const decodedToken = decode(authToken);

      if (decodedToken?.exp) {
        const expiresMs = decodedToken.exp * 1000;
        const timeOut = Math.max(0, expiresMs - Date.now());
        setIdleTimeout(timeOut);
      } else {
        setIdleTimeout(null);
      }
    }
  }, [idleTimeout, authenticated, authToken]);

  useEffect(() => {
    let keepAliveInterval: ReturnType<typeof setInterval> | undefined;
    if (!isIdle && authenticated && idleTimeout) {
      keepAliveInterval = setInterval(keepAliveFunction, idleTimeout / 2);
    } else {
      clearInterval(keepAliveInterval);
    }

    return () => clearInterval(keepAliveInterval);
  }, [keepAliveFunction, authenticated, isIdle, idleTimeout]);

  return children;
}
