import { isValidElement, ReactNode, type FunctionComponent } from 'react';
import classes from './LoaderInterstitial.module.css';
import { Loader } from '@mantine/core';
import useEnvironment from '@/hooks/useEnvironment';
import ScreenStateInfo from '@/components/ScreenStateInfo';
import InfoBanner from '@/components/InfoBanner';

export interface LoaderInterstitialProps {
  title?: string;
  description?: string;
  devInfo?: string | ReactNode;
}

const LoaderInterstitial: FunctionComponent<LoaderInterstitialProps> = ({
  title = 'Loading…',
  description,
  devInfo,
}) => {
  const { isProduction } = useEnvironment();
  const detail =
    devInfo &&
    (isProduction ? null : isValidElement(devInfo) ? (
      devInfo
    ) : (
      <p className={classes.message}>{devInfo}</p>
    ));

  return (
    <div className={classes.root}>
      <ScreenStateInfo
        title={title}
        description={description}
        icon={<Loader size="xl" />}
        footerSection={
          detail && (
            <InfoBanner meaning="info" title="Pre-production info">
              {detail}
            </InfoBanner>
          )
        }
      />
    </div>
  );
};

export default LoaderInterstitial;
