import { UUID } from '@/lib/graphql/scalars';

/**
 * An image and its expected dimensions
 */
export interface ImageConfig {
  readonly url: string;
  readonly width: number;
  readonly height: number;
  readonly alt?: string;
}

export enum HomepageWidget {
  FindCareCard = 'findCareCard',
  OtherWorkBenefits = 'otherWorkBenefits',
  PreventativeToDoList = 'preventativeToDoList',
  WelcomeWidget = 'welcomeWidget',
}

export interface HomepageWidgetConfig {
  readonly component: HomepageWidget;
  readonly props?: Record<string, unknown>;
}

export interface EnrollmentConfig {
  readonly steps: [string, ...string[]];
}

export interface ProgramConfig {
  programId: UUID;
  programSlug: string;
  programName: string;
  programTheme: string;
  contentfulTag: string;

  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  alphaTermsUrl: string;
  noticeOfPrivacyPracticesUrl: string;
  telehealthConsentUrl: string;
  welkinCloudLink: string;

  supportPhoneNumber: string;
  supportEmail: string;
  supportFaqUrl: string;

  programLogo: ImageConfig;
  lightProgramLogo: ImageConfig;

  homepage: HomepageWidgetConfig[];
  enrollment?: EnrollmentConfig;
}
