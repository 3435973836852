import {
  PropsWithChildren,
  useContext,
  useState,
  type FunctionComponent,
} from 'react';
import {
  TenantConfigContext,
  TenantConfiguration,
} from './TenantConfigContext';

export interface TenantConfigProviderProps extends PropsWithChildren {}

/**
 * Stores the configuration for the current domain/subdomain.
 *
 * @see MockTenantConfigProvider for testing.
 */
export const TenantConfigProvider: FunctionComponent<
  TenantConfigProviderProps
> = ({ children }) => {
  const [config, setConfig] = useState<TenantConfiguration | null>(null);

  return (
    <TenantConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </TenantConfigContext.Provider>
  );
};

export const useTenantConfig = (): TenantConfiguration => {
  const ctx = useContext(TenantConfigContext);

  if (ctx === null) {
    throw new Error(
      'useTenantConfig must be used within a TenantConfigProvider'
    );
  }

  return {
    ...ctx.config!,
  };
};

export const useTenantId = (): string | undefined => {
  const ctx = useContext(TenantConfigContext);

  return ctx?.config?.id;
};
